import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import {PermissionService} from "../shared/services/permission.service";
import {environment} from "../environments/environment";
import {RequestsService} from "../shared/services/requests.service";
import {ADMIN_ROUTE_TO_SECTION_MAP} from "../shared/models/admin-section";
import {AdminModuleRoute, BASE_PATH} from "../shared/configs/admin-routes";
import {SessionService} from "../shared/services/session.service";
import {UserHelperService} from "../shared/services/user-helper.service";

@Injectable({
    providedIn: 'root'
})
export class AdminGuardService {
    constructor(
        private userHelperService: UserHelperService,
        private permissionService: PermissionService,
        private router: Router,
        private adminRequestsService: RequestsService,
        private sessionService: SessionService,
    ) {

    }

    async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        try {
            if (environment.withoutAdmin) {
                this.router.navigate([environment.globals.firstAppPage]);
                return false;
            }
            const currentUser = await this.userHelperService.getUserDetails();
            const isAdmin = this.permissionService.couldViewAll();

            const accessibles: string[] = (await this.adminRequestsService.getAdminAccessList(currentUser.userId)).pages;
            const shortenedRoute = state.url.replace(`/${BASE_PATH}/`, "");
            let matchingAccessible: string;
            if (shortenedRoute.includes("user")) {
                matchingAccessible = "USERS";
            } else if (shortenedRoute.includes('course')) {
                matchingAccessible = 'COURSES'
            } else {
                matchingAccessible = ADMIN_ROUTE_TO_SECTION_MAP.get(shortenedRoute);
            }

            //todo remove when backend ready
            const isAccessible = (matchingAccessible && accessibles.includes(matchingAccessible))
                || shortenedRoute === 'uploads'
                || shortenedRoute === 'video-feed'
                || shortenedRoute === 'data-pipelines';
            //const isAccessible = matchingAccessible && accessibles.includes(matchingAccessible)

            let redirectTo: string[];
            if (!isAdmin || this.sessionService.isSudoSession()) {
                redirectTo = [environment.globals.firstAppPage];
            } else if (!isAccessible) {
                redirectTo = [BASE_PATH, AdminModuleRoute[accessibles[0]]];
            }

            if (redirectTo) {
                this.router.navigate(redirectTo);
                return false;
            }

            return true;

        } catch (error) {
            this.router.navigate([environment.globals.firstAppPage]);
            return false;
        }

    }

}
