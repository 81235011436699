import {AdminModuleRoute} from "../configs/admin-routes";

export enum AdminSection {
    USERS = 0,
    ORGANIZATIONS,
    COUPONS,
    PRIVATE_ASSETS,
    TASK_LIST,
    SUDO,
    APPROVALS,
    COLLECTIONS,
    UPLOADS,
    TOPICS,
    COURSES,
    WORKSHOPS,
    EXPERTS,
    DEFAULT_STORIES,
    VIDEO_POPUPS,
    MARKETING_TASKS,
    EMAIL_TEMPLATES,
    NOTIFICATIONS,
    VERSION_CONFIG,
    SCHEDULES,
    CRON_JOBS,
    VIDEO_FEED,
    DATA_PIPELINES
}

export const ADMIN_SECTION_KEYS = Object.values(AdminSection).filter(value => typeof value === 'string');

export const keyOf = (value: number): string => {
    const index = Object.keys(AdminSection).indexOf(value.toString());
    return ADMIN_SECTION_KEYS[index] as string;
}

export const ADMIN_ROUTE_TO_SECTION_MAP = new Map<string, string>();

ADMIN_SECTION_KEYS.forEach(key => ADMIN_ROUTE_TO_SECTION_MAP.set(AdminModuleRoute[key], key as string));
